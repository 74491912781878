<template>
  <Header></Header>
  <div class="relative p-normal">
    <Logo></Logo>
    <!--    form-->
    <!--    form @submit.prevent="handleLogin" @keydown="clear($event.target.id)"-->
    <form v-if="!sendedEmail" @submit.prevent="handlePasswordReset" class="w-full login-form">
      <div>
        <h1 class="text-center font-light">Ingresa tu correo de SoloFoodies</h1>
      </div>
      <div class="p-normal">
        <div class="block-input">
          <!--v-icon name="at" scale="1.4" class="w-16 text-gray-800 dark:text-gray-400" /-->
          <input type="email" id="email" name="email" v-model="user.email" placeholder="Correo electrónico"
                 class="w-full border-gray-300 focus:border-none" required>
        </div>
      </div>

      <button :disabled="loading" class="w-full button uppercase bg-primary text-white flex items-center justify-center space-x-2 disabled:bg-gray-200">
        <a-loader class="w-10 h-6" color="text-white" v-if="loading" />
        <span>Reiniciar contraseña</span>
      </button>

      <div>
        <router-link to="/login" class="text-primary">Ya tengo cuenta</router-link>
      </div>
      <!--div class="flex justify-center">
        <router-link :to="{ name: 'send_password_reset' }" class="my-2 text-sm text-gray-700 dark:text-gray-300">
          {{ lang.login.forgot_password }}
        </router-link>
      </div-->
    </form>
    <div v-else>
      <h3>Se envio el código de verificación a su correo electrónico</h3>
      <div>
        <router-link to="/login" class="text-primary mt-sm">Volver</router-link>
      </div>
    </div>

    <a-alert hide-cancel :show="emailError" title="Ha ocurrido un error" @confirm="emailError = false">
      <p>{{ errorMessage }}</p>
    </a-alert>
  </div>
</template>

<script>
import Header from '@/components/guest/Header'
import Logo from '@/components/Logo'
import { useErrors } from '@/hooks'

export default {
  components: {
    Header,
    Logo
  },
  data () {
    return {
      user: {
        email: '',
        password: '',
        token: ''
      },
      password_confirm: '',
      loading: false,
      sendedEmail: false,
      errorMessage: '',
      emailError: false,
      errorValue: null
    }
  },
  name: 'PasswordReset',
  methods: {
    handlePasswordReset () {
      this.sendedEmail = false
      this.loading = !this.loading
      this.$repository.users
        .sendPasswordReset(this.user.email)
        .then(() => { this.sendedEmail = true })
        .catch(({ response }) => {
          this.emailError = !this.emailError
          if (response.data.message === 'user_not_found') {
            this.errorMessage = 'Usuario no encontrado.'
          } else {
            this.errorMessage = useErrors(response.data.error_code).message
          }
        })
        .finally(() => { this.loading = !this.loading })
    }
  }
}
</script>

<style lang="scss" scoped>
.button{
  margin: 10% auto 15px auto;
}
input[type="checkbox"]{
  vertical-align: middle;
}
.remember-input{
}
.remember-label{
  margin-left: 5px;
  vertical-align: middle;
}
.login-button{
  border-left: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
  height: auto;
}
.logo-container{
  margin-top: 20%;
}
.toast_noty{
  background:white;
  width: 280px;
  margin: 0 auto;
  min-height: 154px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.19);
  border-radius: 10px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;
}
.header_noti,
.footer_noti{
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
.header_noti{
  text-transform: uppercase;
  text-align: left;
}
.footer_noti{
  text-align: right;
  color: #F2B415;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.body_noti{
  padding: 0 20px 47px 20px;
  .icon_noti{
    font-size: 35px;
    display: inline-block;
    vertical-align: middle;
  }
  .text_noti{
    display: inline-block;
    width: calc(100% - 35px);
    text-align: left;
    line-height: 1.2;
    padding-left: 10px;
    vertical-align: middle;
  }
}
</style>
