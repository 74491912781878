<template>
  <header class="text-left font-bold border-b border-gray p-normal">
    <div @click="goBack">
      <p class="uppercase cursor-pointer"><i class="icon-arrowLeft"></i>  Regresar</p>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: {
    title: String
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
header{
  min-height: 30px;
}

</style>
